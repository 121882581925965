import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { StaticPageProps } from './types';
import ErrorPage from '../../pages/error/[slug]';
import StaticPageLayout from '../../layouts/StaticPageLayout';
import Header from './Header';
import Wrapper from './Wrapper';
import Footer from './Footer';

const StaticPage: React.FunctionComponent<StaticPageProps> = props => {
    const theme = useTheme<Theme>();
    const { page, menu, sidebar, footer } = props;

    if (!page) { return <ErrorPage code='404'/>; }

    const {
        name,
        header,
        content,
        slug,
        title,
        description,
        keywords,
    } = page;
    const menuItems = menu.body;
    const sidebarData = sidebar ? sidebar.content : null;
    const footerData = footer.content;

    return <StaticPageLayout
        title={ title || name }
        description={ description }
        keywords={ keywords }
        withActions={ false }
        headerBackground={ theme.palette.primary.main }
        headerBottomSticky={{
            element: <Header data={ header } />,
            zIndex: 10,
            top: 0,
            disableSticky: true,
        }}
        menuItems={ menuItems }
        footer={ <Footer data={ footerData } /> }
        mobileLaptopBottomSticky={ props?.mobileLaptopBottomSticky }
    >
        <Wrapper content={ content } sidebar={ sidebarData } slug={ slug } />
    </StaticPageLayout>;
};

export default StaticPage;
